import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';

import {
  PreloadingStrategy,
  Route,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
var delay: number = 1000000;
@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  constructor(private router: Router) {}
  preload(route: Route, loadMe: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload']) {
      var delay: number = route.data['delay'] ? route.data['delay'] : 0;

      return timer(delay).pipe(
        flatMap((_) => {
          return loadMe();
        })
      );
    } else {
      return of(null);
    }
  }
}
