import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpService } from '../services/http.service';
import { catchError } from 'rxjs/operators';
import { environment } from '@env';
import { AuthObject } from '@core/models/user';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authObject: AuthObject;
  constructor(private cookie: CookieService, private http: HttpService) {
    this.cookie.get('userData')
    this.authObject = {
      Username:  this.cookie.get('Username'),
      Password:  this.cookie.get('Password')
      // Username: environment['Username'],
      // Password: environment['Password']
    };
  }
  // token: string;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.token = this.http.getToken();

    if (this.http.isLoggedIn() && this.cookie.get('Username') !== null && this.cookie.get('Password') !== null && request.url.includes(environment['url'])) {
      if (request.url.split('/')[0] !== 'assets') {
        request.body['login'] = {
          Username:  this.cookie.get('Username'),
          Password:  this.cookie.get('Password')
          // Username: environment['Username'],
          // Password: environment['Password']
        };

      }
      // request = request.clone({
      //   setHeaders: {
      //     Authorization: this.token
      //   }
      // });
    }
    return next.handle(request);
  }
}
