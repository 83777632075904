import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './pages/nottfound/nottfound.component';
import {ButtonModule} from 'primeng/button';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    NotFoundComponent
  ],
  imports: [
    ButtonModule
  ]
})
export class FeatErrorsModule {
  constructor(){

  }
}
