<div class="left_col scroll-view">
  <div class="navbar nav_title" style="border: 0">
    <a href="https://fleet.nasam.net/home.aspx" class="site_title" style="padding-left: unset; text-align: center;">
      <!-- <p style="display: inline-block">OJO</p> -->
      <span translate> SAUDI</span></a
    >
  </div>

  <div class="clearfix"></div>

  <!-- menu profile quick info -->
  <div class="profile clearfix">
    <div class="profile_pic">
      <img
        src="assets/images/Saudi Tracking Logo_300dpi.jpg"
        alt="..."
        class="img-circle profile_img"
        style="border-radius: 48%; width: 100px"
      />
    </div>
    <div
      class="profile_info"
      style="padding: 30px 10px 10px; width: 50%; float: right"
    >
    <span translate>{{ "Welcome," | translate }}</span>
    <h2>{{ userData.userName }}</h2>
    </div>
  </div>
  <!-- /menu profile quick info -->

  <br />

  <!-- sidebar menu -->
  <div id="sidebar-menu" class="main_menu_side hidden-print main_menu">
    <div class="menu_section">
      <h3>{{ "GENERAL" | translate }}</h3>
      <ul class="nav side-menu">
        <li [hidden]="!role.fleet_pg">
          <a (click)="arrow(1)"
            ><i class="fa fa-home"></i> {{ "Home" | translate }}
            <span id="fa1" class="fa fa-chevron-right"></span
          ></a>
          <ul id="ul1" class="nav child_menu">
            <li>
              <a id="dashboard" routerLink="home/dashboard">{{
                "Dashboard" | translate
              }}</a>
            </li>
          </ul>
        </li>

        <li>
          <a (click)="arrow(2)">
            <i class="fas fa-th"></i>
            {{ "Items" | translate }}
            <span id="fa2" class="fa fa-chevron-right"></span>
          </a>
          <ul id="ul2" class="nav child_menu">
            <!-- Items -->
            <li [hidden]="!role.items_cat">
              <a id="category" routerLink="items/category">
                {{ "Category" | translate }}
              </a>
            </li>
            <li [hidden]="!role.items_sub_cat">
              <a id="subCategory" routerLink="items/subCategory">
                {{ "Sub Category" | translate }}
              </a>
            </li>
            <li [hidden]="!role.uom">
              <a id="uom" routerLink="items/uom"> {{ "UOM" | translate }} </a>
            </li>
            <li [hidden]="!role.currency">
              <a id="currency" routerLink="items/currency">
                {{ "Currency" | translate }}
              </a>
            </li>
            <li [hidden]="!role.fuel">
              <a id="fuel" routerLink="items/fuel">
                {{ "Fuel" | translate }}
              </a>
            </li>
            <li [hidden]="!role.items">
              <a id="itemlist" routerLink="items/itemlist">
                {{ "Items" | translate }}
              </a>
            </li>
          </ul>
        </li>

        <!-- TransActions -->
        <li>
          <a (click)="arrow(3)"
            ><i class="fa fa-refresh"></i> {{ "Transactions" | translate }}
            <span id="fa3" class="fa fa-chevron-right"></span
          ></a>

          <ul id="ul3" class="nav child_menu">
            <!-- 1 -->
            <li [hidden]="!role.suppliers">
              <a (click)="arrow(4)"
                >{{ "Suppliers" | translate
                }}<span id="fa4" class="fa fa-chevron-right"></span
              ></a>

              <ul id="ul4" class="nav child_menu">
                <li class="sub_menu">
                  <a id="suppliers" routerLink="transactions/suppliers">{{
                    "Suppliers" | translate
                  }}</a>
                </li>
              </ul>

              <ul id="ul4" class="nav child_menu">
                <li class="sub_menu">
                  <a
                    id="suppliersitems"
                    routerLink="transactions/suppliersitems"
                    >{{ "Supplier's Items" | translate }}</a
                  >
                </li>
              </ul>
            </li>

            <li>
              <a (click)="arrow(5)"
                >{{ "Stock" | translate
                }}<span id="fa5" class="fa fa-chevron-right"></span
              ></a>
              <ul id="ul5" class="nav child_menu">
                <li class="sub_menu" [hidden]="!role.main_stock">
                  <a id="mainstock" routerLink="transactions/mainstock">{{
                    "Main Stock" | translate
                  }}</a>
                </li>
                <li class="sub_menu" [hidden]="!role.sub_stock">
                  <a id="substock" routerLink="transactions/substock">{{
                    "Sub Stock" | translate
                  }}</a>
                </li>
              </ul>
            </li>

            <li [hidden]="!role.customers">
              <a id="customers" routerLink="transactions/customers">{{
                "Customers" | translate
              }}</a>
            </li>

            <li [hidden]="!role.transaction_type">
              <a
                id="transactiontype"
                routerLink="transactions/transactiontype"
                >{{ "Transaction Type" | translate }}</a
              >
            </li>

            <li [hidden]="!role.transaction">
              <a id="transactions" routerLink="transactions/transactions">{{
                "Transactions" | translate
              }}</a>
            </li>

            <li [hidden]="!role.invoices">
              <a id="invoices" routerLink="transactions/invoices">{{
                "Invoices" | translate
              }}</a>
            </li>
            <li [hidden]="!role.invoices">
              <a id="addinvoices" routerLink="transactions/addinvoices">{{
                "Add Invoices" | translate
              }}</a>
            </li>
          </ul>
        </li>

        <!-- fines -->
        <li>
          <a (click)="arrow(6)">
            <i class="fa fa-arrow-down"></i>
            {{ "Fines" | translate }}
            <span id="fa6" class="fa fa-chevron-right"></span>
          </a>
          <ul id="ul6" class="nav child_menu">
            <!-- Fines -->
            <li [hidden]="!role.fines">
              <a id="getfines" routerLink="fines/getfines">
                {{ "Fines" | translate }}
              </a>
            </li>
            <!-- Drivers -->
            <li [hidden]="!role.drivers">
              <a id="getdrivers" routerLink="fines/getdrivers">
                {{ "Drivers" | translate }}
              </a>
            </li>
          </ul>
        </li>

        <!--  Working Orders   -->
        <li>
          <a (click)="arrow(7)">
            <i class="fa fa-pencil"></i>
            {{ "Working Orders" | translate }}
            <span id="fa7" class="fa fa-chevron-right"></span>
          </a>
          <ul id="ul7" class="nav child_menu">
            <!-- Items -->
            <li [hidden]="!role.working_orders">
              <a id="workingorders" routerLink="workingorders/workingorders">
                {{ "Working Orders" | translate }}
              </a>
            </li>

            <li [hidden]="!role.maintenance_types">
              <a
                id="maintenancestype"
                routerLink="workingorders/maintenancestype"
              >
                {{ "Maintenances Types" | translate }}
              </a>
            </li>

            <li [hidden]="!role.maintainance_centers">
              <a
                id="maintenancescenter"
                routerLink="workingorders/maintenancescenter"
              >
                {{ "Maintenances Centers" | translate }}
              </a>
            </li>

            <li [hidden]="!role.complaint">
              <a id="complaints" routerLink="workingorders/complaints">
                {{ "Complaints" | translate }}
              </a>
            </li>

            <li [hidden]="!role.Technitaians">
              <a id="technician" routerLink="workingorders/technician">
                {{ "Technicians" | translate }}
              </a>
            </li>
          </ul>
        </li>

        <!--  Reports   -->
        <li>
          <a (click)="arrow(8)">
            <i class="fa fa-th-large"></i>
            {{ "Reports" | translate }}
            <span id="fa8" class="fa fa-chevron-right"></span>
          </a>
          <ul id="ul8" class="nav child_menu">
            <!-- Items -->
            <li [hidden]="!role.complains_report">
              <a id="complaints" routerLink="reports/complaints">
                {{ "Complaints" | translate }}
              </a>
            </li>
            <li [hidden]="!role.report">
              <a id="fines" routerLink="reports/fines">
                {{ "Fines" | translate }}
              </a>
            </li>
            <li [hidden]="!role.financial_report">
              <a id="financials" routerLink="reports/financials">
                {{ "Financials" | translate }}
              </a>
            </li>
            <li [hidden]="!role.transaction_report">
              <a id="transactions" routerLink="reports/transactions">
                {{ "Transactions" | translate }}
              </a>
            </li>
            <li [hidden]="!role.report_invoice">
              <a id="invoices" routerLink="reports/invoices">
                {{ "Invoices" | translate }}
              </a>
            </li>
            <li [hidden]="!role.report_working_orders">
              <a id="workingorders" routerLink="reports/workingorders">
                {{ "Working Orders" | translate }}
              </a>
            </li>
          </ul>
        </li>

        <!-- Nitifications -->
        <li>
          <a (click)="arrow(9)">
            <i class="fa fa-bell-o"></i>
            {{ "Notifications" | translate }}
            <span id="fa9" class="fa fa-chevron-right"></span>
          </a>
          <ul id="ul9" class="nav child_menu">
            <!-- Fines -->
            <li [hidden]="!role.notification_after_date">
              <a
                id="notificationafter"
                routerLink="notifications/notificationafter"
              >
                {{ "Notifications After Date" | translate }}
              </a>
            </li>
            <!-- Drivers -->
            <li [hidden]="!role.notification_before_date">
              <a
                id="notificationbefore"
                routerLink="notifications/notificationbefore"
              >
                {{ "Notifications Before Date" | translate }}
              </a>
            </li>
          </ul>
        </li>
        <!--



        <li><a><i class="fa fa-sitemap"></i> Multilevel Menu <span class="fa fa-chevron-down"></span></a>
          <ul class="nav child_menu"  id="test">
              <li><a href="#level1_1">Level One</a>
              <li><a id="click">Level One<span class="fa fa-chevron-down"></span></a>
                <ul class="nav child_menu">
                  <li class="sub_menu"><a href="level2.html">Level Two</a>
                  </li>
                  <li><a href="#level2_1">Level Two</a>
                  </li>
                  <li><a href="#level2_2">Level Two</a>
                  </li>
                </ul>
              </li>
              <li><a href="#level1_2">Level One</a>
              </li>
          </ul>
        </li> -->

        <!-- Admins -->
        <!-- <li>
          <a
            ><i class="fas fa-users-cog" aria-hidden="true"></i> Admins
            <span class="fa fa-chevron-right"></span
          ></a>
          <ul id="ul" class="nav child_menu">
            <li><a id="category" routerLink="superadmin">Super Admins</a></li>
            <li><a id="category" routerLink="orgadmin">Organizations Admins</a></li>
            <li><a id="category" routerLink="addadmin">Add Admin</a></li>
          </ul>
        </li> -->

        <!-- Patient -->
        <!-- <li>
          <a

            ><i class="fas fa-procedures" aria-hidden="true"></i> Patients
            <span class="fa fa-chevron-right"></span
          ></a>
          <ul id="ul" class="nav child_menu">
            <li><a id="category" routerLink="allpatient">Organizations Patients</a></li>
          </ul>
        </li> -->

        <!-- Configs -->
        <!-- <li>
          <a
            ><i class="fas fa-cogs"></i> Configuration
            <span class="fa fa-chevron-right"></span
          ></a>
          <ul id="ul" class="nav child_menu">
            <li>
              <a>Mail Server Config<span class="fa fa-chevron-right"></span></a>
              <ul id="ul" class="nav child_menu">
                <li class="sub_menu">
                  <a routerLink="mailserver">Mail Server</a>
                </li>
                <li class="sub_menu">
                  <a routerLink="addmailserver">Add Mail Server</a>
                </li>
              </ul>
            </li>

            <li>
              <a
                >Mail Template Config<span class="fa fa-chevron-right"></span
              ></a>
              <ul id="ul" class="nav child_menu">
                <li class="sub_menu">
                  <a routerLink="mailtemplate">Mail Template</a>
                </li>
                <li class="sub_menu">
                  <a routerLink="addmailtemplate">Add Mail Template</a>
                </li>
              </ul>
            </li>
          </ul>
        </li> -->

        <!-- <li><a id="category" routerLink="organization"><i class="fa fa-building-o"></i> Organizations </a></li> -->
        <!-- <li><a id="category"><i class="fa fa-desktop"></i> UI Elements <span
                          class="fa fa-chevron-right"></span></a>
                  <ul id="ul" class="nav child_menu">
                      <li><a id="category" href="general_elements.html">General Elements</a></li>
                      <li><a id="category" href="media_gallery.html">Media Gallery</a></li>
                      <li><a id="category" href="typography.html">Typography</a></li>
                      <li><a id="category" href="icons.html">Icons</a></li>
                      <li><a id="category" href="glyphicons.html">Glyphicons</a></li>
                      <li><a id="category" href="widgets.html">Widgets</a></li>
                      <li><a id="category" href="invoice.html">Invoice</a></li>
                      <li><a id="category" href="inbox.html">Inbox</a></li>
                      <li><a id="category" href="calendar.html">Calendar</a></li>
                  </ul>
              </li>
              <li><a id="category"><i class="fa fa-table"></i> Tables <span class="fa fa-chevron-right"></span></a>
                  <ul id="ul" class="nav child_menu">
                      <li><a id="category" href="tables.html">Tables</a></li>
                      <li><a id="category" href="tables_dynamic.html">Table Dynamic</a></li>
                  </ul>
              </li>
              <li><a id="category"><i class="fa fa-bar-chart-o"></i> Data Presentation <span
                          class="fa fa-chevron-right"></span></a>
                  <ul id="ul" class="nav child_menu">
                      <li><a id="category" href="chartjs.html">Chart JS</a></li>
                      <li><a id="category" href="chartjs2.html">Chart JS2</a></li>
                      <li><a id="category" href="morisjs.html">Moris JS</a></li>
                      <li><a id="category" href="echarts.html">ECharts</a></li>
                      <li><a id="category" href="other_charts.html">Other Charts</a></li>
                  </ul>
              </li>
              <li><a id="category"><i class="fa fa-clone"></i>Layouts <span class="fa fa-chevron-right"></span></a>
                  <ul id="ul" class="nav child_menu">
                      <li><a id="category" href="fixed_sidebar.html">Fixed Sidebar</a></li>
                      <li><a id="category" href="fixed_footer.html">Fixed Footer</a></li>
                  </ul>
              </li> -->
      </ul>
    </div>
    <!-- <div class="menu_section">
          <h3>Live On</h3>
          <ul class="nav side-menu">
              <li><a id="category"><i class="fa fa-bug"></i> Additional Pages <span
                          class="fa fa-chevron-right"></span></a>
                  <ul id="ul" class="nav child_menu">
                      <li><a id="category" href="e_commerce.html">E-commerce</a></li>
                      <li><a id="category" href="projects.html">Projects</a></li>
                      <li><a id="category" href="project_detail.html">Project Detail</a></li>
                      <li><a id="category" href="contacts.html">Contacts</a></li>
                      <li><a id="category" href="profile.html">Profile</a></li>
                  </ul>
              </li>
              <li><a id="category"><i class="fa fa-windows"></i> Extras <span class="fa fa-chevron-right"></span></a>
                  <ul id="ul" class="nav child_menu">
                      <li><a id="category" href="page_403.html">403 Error</a></li>
                      <li><a id="category" href="page_404.html">404 Error</a></li>
                      <li><a id="category" href="page_500.html">500 Error</a></li>
                      <li><a id="category" href="plain_page.html">Plain Page</a></li>
                      <li><a id="category" href="login.html">Login Page</a></li>
                      <li><a id="category" href="pricing_tables.html">Pricing Tables</a></li>
                  </ul>
              </li>

              <li><a id="category" href="javascript:void(0)"><i class="fa fa-laptop"></i> Landing Page <span
                          class="label label-success pull-right">Coming Soon</span></a></li>
          </ul>
      </div> -->
  </div>

  <!-- /menu footer buttons -->
  <div class="sidebar-footer hidden-small">
    <!-- <a style="color: unset;" data-toggle="tooltip" data-placement="top" title="Settings">
          <span class="glyphicon glyphicon-cog" aria-hidden="true"></span>
      </a>
      <a style="color: unset;" data-toggle="tooltip" data-placement="top" title="FullScreen">
          <span class="glyphicon glyphicon-fullscreen" aria-hidden="true"></span>
      </a>
      <a style="color: unset;" data-toggle="tooltip" data-placement="top" title="Lock">
          <span class="glyphicon glyphicon-eye-close" aria-hidden="true"></span>
      </a>
      <a style="color: unset;" data-toggle="tooltip" data-placement="top" title="Logout" href="login.html">
          <span class="glyphicon glyphicon-off" aria-hidden="true"></span>
      </a> -->
    <a
      style="color: unset"
      data-toggle="tooltip"
      href="https://fms.nasam.net/home/dashboard"
      data-placement="top"
      title="{{ 'Settings' | translate }}"
    >
      <span class="glyphicon glyphicon-cog" aria-hidden="true"></span>
    </a>
    <a
      style="color: unset"
      data-toggle="tooltip"
      (click)="openFullscreen()"
      data-placement="top"
      title="{{ 'FullScreen' | translate }}"
    >
      <span class="glyphicon glyphicon-fullscreen" aria-hidden="true"></span>
    </a>
    <a
      style="color: unset"
      data-toggle="tooltip"
      (click)="refresh()"
      data-placement="top"
      title="{{ 'Refresh' | translate }}"
    >
      <span class="glyphicon glyphicon-refresh" aria-hidden="true"></span>
    </a>
    <a
      style="color: unset"
      data-toggle="tooltip"
      (click)="http.logout()"
      data-placement="top"
      title="{{ 'Logout' | translate }}"
    >
      <span class="glyphicon glyphicon-off" aria-hidden="true"></span>
    </a>
  </div>
</div>
