import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { PreloadingStrategy, Route, Router, RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { FeatErrorsModule } from '@features/feat-errors/feat-errors.module';
import { CustomPreloadingStrategy } from '@core/services/custom-preloading.service';


import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// registerLocaleData(en);
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FeatErrorsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule.http(),
    CoreModule.cookieService(),
    CoreModule.tokenInterceptor(),

  ],
  providers: [
    { provide: NZ_ICONS, useValue: icons },
    CustomPreloadingStrategy
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}



