<div class="nav_menu">
  <nav>
    <div class="nav toggle">
      <a id="menu_toggle" style="color: #5a738e"><i class="fa fa-bars"></i></a>
    </div>

    <ul class="nav navbar-nav navbar-right">
      <li class="">
        <a
          id="jumpTo"
          href="https://fleet.nasam.net/home.aspx"
          class="user-profile dropdown-toggle"
          aria-expanded="false"

        >
          <!-- <img src="assets/images/ojologofor.png" alt="" />
          {{
            userData.userName
          }} -->
          {{'GPS Tracking' | translate}}
          <span class="fa fa-angle-left"></span>
        </a>
        <ul class="dropdown-menu dropdown-usermenu pull-right">


          <li style="height: 65px;" >
            <a href="https://fleet.nasam.net/Default.aspx">
              <span class="p-text-uppercase">{{'Home' | translate}}</span>
            </a>
          </li>

          <li style="height: 65px;" [hidden]="!jumpToRole.gps_tracking" id="jump_gps">
            <a href="https://ojoplatform.com/redirect.aspx?type=tr">
              <span class="p-text-uppercase">{{'GPS Tracking' | translate}}</span>
            </a>
          </li>
          <!-- <li style="height: 65px;" [hidden]="!jumpToRole.fms" id="jump_fms">
            <a href="https://ojoplatform.com/fms.aspx">
              <span class="p-text-uppercase">Fleet Management</span>
            </a>
          </li> -->
          <li style="height: 65px;" [hidden]="!jumpToRole.crm" id="jump_crm">
            <a href="https://ojoplatform.com/crm-platform.aspx">
              <span class="p-text-uppercase">{{'CRM' | translate}}</span>
            </a>
          </li>
          <li style="height: 65px;" [hidden]="!jumpToRole.workforce" id="jump_workforce">
            <a href="https://ojoplatform.com/work-force.aspx">
              <span class="p-text-uppercase">{{'WorkForce' | translate}}</span>
            </a>
          </li>

          <li style="height: 65px;" [hidden]="!jumpToRole.events" id="jump_events" style="display: none">
            <a href="https://ojoplatform.com/home.aspx">
              <span class="p-text-uppercase">{{'Events Management' | translate}}</span>
            </a>
          </li>
          <li style="height: 65px;" [hidden]="!jumpToRole.inventory" id="jump_inventory">
            <a href="https://ojoplatform.com/redirect.aspx?type=inv">
              <span class="p-text-uppercase">{{'Inventory' | translate}}</span>
            </a>
          </li>
          <li style="height: 65px;" [hidden]="!jumpToRole.hr" id="jump_hr">
            <a
            routerLink
            (click)="redirectUrl()"

            >
              <span class="p-text-uppercase">{{'HR' | translate}}</span>
            </a>
          </li>
          <li style="height: 65px;" [hidden]="!jumpToRole.sdms" id="jump_sdms">
            <a href="https://ojoplatform.com/sdms.aspx">
              <span class="p-text-uppercase">{{'SDMS' | translate}}</span>
            </a>
          </li>
          <li style="height: 65px;" [hidden]="!jumpToRole.indroors" id="jump_indoors">
            <a href="https://ojoplatform.com/indoor.aspx">
              <span class="p-text-uppercase">Indoors</span>
            </a>
          </li>
          <li style="height: 65px;" [hidden]="!jumpToRole.tenders" id="jump_tender">
            <a href="https://ojoplatform.com/tender.aspx">
              <span class="p-text-uppercase">{{'Tender' | translate}}</span>
            </a>
          </li>
          <li style="height: 65px;" [hidden]="!jumpToRole.talk" id="jump_talk">
            <a href="https://ojoplatform.com/vmr.aspx">
              <span class="p-text-uppercase">{{'OJO Talk' | translate}}</span>
            </a>
          </li>
          <li >
            <a (click)="http.logout()"
              ><i class="fas fa-sign-out-alt pull-right"></i> {{'Log Out' | translate}}</a
            >
          </li>
        </ul>
      </li>

      <!-- <li role="presentation" class="dropdown">
              <a href="https://ojoplatform.com/javascript:;" class="dropdown-toggle info-number" data-toggle="dropdown"
                  aria-expanded="false">
                  <i class="fa fa-envelope-o"></i>
                  <span class="badge bg-green">6</span>
              </a>
              <ul id="menu1" class="dropdown-menu list-unstyled msg_list" role="menu">
                  <li>
                      <a>
                          <span class="image"><img src="assets/images/img.jpg" alt="Profile Image" /></span>
                          <span class="p-text-uppercase">
                              <span class="p-text-uppercase">John Smith</span>
                              <span class="time">3 mins ago</span>
                          </span>
                          <span class="message">
                              Film festivals used to be do-or-die moments for movie makers. They were
                              where...
                          </span>
                      </a>
                  </li>
                  <li>
                      <a>
                          <span class="image"><img src="assets/images/img.jpg" alt="Profile Image" /></span>
                          <span class="p-text-uppercase">
                              <span class="p-text-uppercase">John Smith</span>
                              <span class="time">3 mins ago</span>
                          </span>
                          <span class="message">
                              Film festivals used to be do-or-die moments for movie makers. They were
                              where...
                          </span>
                      </a>
                  </li>
                  <li>
                      <a>
                          <span class="image"><img src="assets/images/img.jpg" alt="Profile Image" /></span>
                          <span class="p-text-uppercase">
                              <span class="p-text-uppercase">John Smith</span>
                              <span class="time">3 mins ago</span>
                          </span>
                          <span class="message">
                              Film festivals used to be do-or-die moments for movie makers. They were
                              where...
                          </span>
                      </a>
                  </li>
                  <li>
                      <a>
                          <span class="image"><img src="assets/images/img.jpg" alt="Profile Image" /></span>
                          <span class="p-text-uppercase">
                              <span class="p-text-uppercase">John Smith</span>
                              <span class="time">3 mins ago</span>
                          </span>
                          <span class="message">
                              Film festivals used to be do-or-die moments for movie makers. They were
                              where...
                          </span>
                      </a>
                  </li>
                  <li>
                      <div class="text-center">
                          <a>
                              <strong>See All Alerts</strong>
                              <i class="fa fa-angle-right"></i>
                          </a>
                      </div>
                  </li>
              </ul>
          </li> -->
    </ul>
  </nav>
</div>
