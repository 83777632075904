import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
constructor(private cookie: CookieService,public translate: TranslateService){}
ngOnInit() {
    this.translate.setDefaultLang(this.cookie.get('Lang') || 'en-us')
  }

}
