import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadingStrategy, Route, PreloadAllModules } from '@angular/router';
import { NotFoundComponent } from '@features/feat-errors/pages/nottfound/nottfound.component';
import { AuthGurd } from '@core/guards/auth-guard';
import { CustomPreloadingStrategy } from '@core/services/custom-preloading.service';
import { IsLogged } from '@core/guards/auth.guard.islogged';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [IsLogged],
    loadChildren: () => import('@layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule),
  },
  {
    path: '',
    canActivate: [AuthGurd],// remove user & pass from environment
    loadChildren: () => import('@layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule),
  },
  {
    path: '**', canActivate: [AuthGurd], component: NotFoundComponent
  },

];




@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategy })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { };


