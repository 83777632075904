import { ModuleWithProviders, NgModule } from '@angular/core';
import { ComponentsModule } from './components/components.module';
import { AuthGurd } from './guards/auth-guard';
import { IsLogged } from './guards/auth.guard.islogged';
import { HttpService } from './services/http.service';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [],
  exports: [ComponentsModule]
})
export class CoreModule {
  static authGuard(): ModuleWithProviders<any> {
    return { ngModule: CoreModule, providers: [AuthGurd] };
  }

  static isLogged(): ModuleWithProviders<any> {
    return { ngModule: CoreModule, providers: [IsLogged] };
  }

  static http(): ModuleWithProviders<any> {
    return { ngModule: CoreModule, providers: [HttpService] };
  }

  static tokenInter(): ModuleWithProviders<any> {
    return { ngModule: CoreModule, providers: [TokenInterceptor] };
  }

  static errorInter(): ModuleWithProviders<any> {
    return { ngModule: CoreModule, providers: [ErrorInterceptor] };
  }


  static cookieService(): ModuleWithProviders<any> {
    return { ngModule: CoreModule, providers: [CookieService] };
  }

  static tokenInterceptor(): ModuleWithProviders<any> {
    return { ngModule: CoreModule, providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }] };
  }

  static errorInterceptor(): ModuleWithProviders<any> {
    return { ngModule: CoreModule, providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }] };
  }
}


