import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role, UserData } from '@core/models/user';
import { HttpService } from '@core/services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  constructor(public route: Router, private cookie: CookieService,public http:HttpService, public translate: TranslateService) {
    this.translate.setDefaultLang(this.cookie.get('Lang') || 'en-us');
  }
  userData: UserData = JSON.parse(this.cookie.get('userData'));
  role: Role = JSON.parse(this.cookie.get('role'));

  refresh(){
    window.location.reload()
  }
  ngOnInit() {

    $('li').each(function () {
      if ($(this).hasClass('active')) {
        var span = $(this).find('span')[0];
        $(span).removeClass('fa-chevron-right').addClass('fa-chevron-down');
      }
    });

    var $SIDEBAR_MENU = $('#sidebar-menu');
    $SIDEBAR_MENU.find('a').on('click', function () {
      if ($(this).parent().parent().hasClass('child_menu')) {
        var that = this;
        $SIDEBAR_MENU.find('a').each(function () {
          if (

            $(this).parent().parent().hasClass('child_menu') &&
            this !== that &&

           $(this).text() !== 'Stock' && $(this).text() !== 'Suppliers'
          ) {
            $(this).parent().removeClass('active');
          }
        });
      }
    });



  }

  openFullscreen() {
    var elem: any = document.documentElement;
     if (elem.requestFullscreen) {
         elem.requestFullscreen();
     } else if (elem.mozRequestFullScreen) { /* Firefox */
         elem.mozRequestFullScreen();
     } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
         elem.webkitRequestFullscreen();
     } else if (elem.msRequestFullscreen) { /* IE/Edge */
         elem.msRequestFullscreen();
     }
 }


  arrow(number: number) {
    if (number === 1) {
      $('#fa1').toggleClass('fa-chevron-right', 'fa-chevron-down');
      $('#fa1').toggleClass('fa-chevron-down', 'fa-chevron-right');
      $('#fa3, #fa2, #fa6 ,#fa7, #fa8, #fa9')
        .removeClass('fa-chevron-down')
        .addClass('fa-chevron-right');
    } else if (number === 2) {
      $('#fa2').toggleClass('fa-chevron-right', 'fa-chevron-down');
      $('#fa2').toggleClass('fa-chevron-down', 'fa-chevron-right');
      $('#fa1, #fa6 ,#fa7, #fa3, #fa8, #fa9')
        .removeClass('fa-chevron-down')
        .addClass('fa-chevron-right');
    } else if (number === 3) {
      $('#fa3').toggleClass('fa-chevron-right', 'fa-chevron-down');
      $('#fa3').toggleClass('fa-chevron-down', 'fa-chevron-right');
      $('#fa1, #fa2, #fa4, #fa5, #fa6 ,#fa7, #fa8, #fa9')
        .removeClass('fa-chevron-down')
        .addClass('fa-chevron-right');
    } else if (number === 4) {
      $('#fa4').toggleClass('fa-chevron-right', 'fa-chevron-down');
      $('#fa4').toggleClass('fa-chevron-down', 'fa-chevron-right');

      $('#fa1, #fa2')
        .removeClass('fa-chevron-down')
        .addClass('fa-chevron-right');
    } else if (number === 5) {
      $('#fa5').toggleClass('fa-chevron-right', 'fa-chevron-down');
      $('#fa5').toggleClass('fa-chevron-down', 'fa-chevron-right');

      $('#fa1, #fa2')
        .removeClass('fa-chevron-down')
        .addClass('fa-chevron-right');
    } else if (number === 6) {
      $('#fa6').toggleClass('fa-chevron-right', 'fa-chevron-down');
      $('#fa6').toggleClass('fa-chevron-down', 'fa-chevron-right');
      $('#fa1,#fa2,  #fa3,#fa7, #fa8, #fa9')
        .removeClass('fa-chevron-down')
        .addClass('fa-chevron-right');
    } else if (number === 7) {
      $('#fa7').toggleClass('fa-chevron-right', 'fa-chevron-down');
      $('#fa7').toggleClass('fa-chevron-down', 'fa-chevron-right');
      $('#fa1,#fa2,  #fa3, #fa6, #fa8, #fa9')
        .removeClass('fa-chevron-down')
        .addClass('fa-chevron-right');

    } else if (number === 8) {
      $('#fa8').toggleClass('fa-chevron-right', 'fa-chevron-down');
      $('#fa8').toggleClass('fa-chevron-down', 'fa-chevron-right');
      $('#fa1,#fa2,  #fa3, #fa6, #fa7, #fa9')
        .removeClass('fa-chevron-down')
        .addClass('fa-chevron-right');
    } else if (number === 9) {
      $('#fa9').toggleClass('fa-chevron-right', 'fa-chevron-down');
      $('#fa9').toggleClass('fa-chevron-down', 'fa-chevron-right');
      $('#fa1,#fa2,  #fa3, #fa6, #fa7, #fa8')
        .removeClass('fa-chevron-down')
        .addClass('fa-chevron-right');
    }
  }
}
