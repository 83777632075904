import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaderResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpService } from '../services/http.service';
import { CookieService } from 'ngx-cookie-service';
import { TokenObject, UserData } from '../models/user';
declare var Swal;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private cookie: CookieService, private http: HttpService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap((res :any) => {
        if(res?.body?.Status?.code === 403 || res?.body?.Status?.code === 401 ){
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: res.body.Status.message,
            showConfirmButton: false,
            timer: 5000,
          });
          setTimeout(() => {
            window.location.href = 'https://fleet.nasam.net/Default.aspx#';
          }, 3000);
        }
      }),
      catchError((err: any) => {
      if ([401, 403].includes(err.status) && this.http.getUserData()) {

        // auto logout if 401 or 403 response returned from api
        // parse json object from base64 encoded jwt token

        // const userData: UserData = JSON.parse(this.cookie.get('userData'));
        // const expires = new Date(userData.endDate * 1000);
        // const chekvalidation = expires.getTime() <= Date.now();

        // if (chekvalidation) {
          console.log(err)
          this.http.logout();
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'your session has expired',
            showConfirmButton: true,
            timer: 5000,
          });
          setTimeout(() => {
            window.location.href = 'https://fleet.nasam.net/Default.aspx#';
          }, 3000);
          const error = (err && err.error && err.error.message) || err.statusText;
          return throwError(error);
        // }
      }
    })
    );
  }



}
