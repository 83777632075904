import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserData ,JumpToRole} from '@core/models/user';
import { HttpService } from '@core/services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  userData: UserData;
  jumpToRole: JumpToRole;
  constructor(
    private router: Router,
    public http: HttpService,
    private cookie: CookieService,
    public translate: TranslateService

  ) {
    this.userData = JSON.parse(this.cookie.get('userData'));
    this.jumpToRole = JSON.parse(this.cookie.get('jumpToRole'));
    this.translate.setDefaultLang(this.cookie.get('Lang') || 'en-us');
  }
  ngOnInit() {}

  redirectUrl(){
    var key ='AS524s'
    var id = btoa(this.cookie.get('Username'));
    var tol =  btoa(this.cookie.get('Password') + key);
    var lang =  btoa(this.cookie.get('Lang'));
    window.location.href = `https://ojoplatform.com/hr/log?id=${id}&tol=${tol}&lang=${lang}`

  }
}
