import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: AnalyserNode;

@Component({
  selector: 'app-nottfound',
  templateUrl: './nottfound.component.html',
  styleUrls: ['./nottfound.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(public route: Router) { }

  ngOnInit(): void {
    // $(document).ready(function () {


    //   // $('<script/>', { type: 'text/javascript', src: 'assets/vendors/jquery/dist/jquery.min.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/bootstrap/dist/js/bootstrap.min.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/fastclick/lib/fastclick.js' }).appendTo('body');
    //   // $('<script/>', { type: 'text/javascript', src: 'assets/vendors/nprogress/nprogress.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/Chart.js/dist/Chart.min.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/gauge.js/dist/gauge.min.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/bootstrap-progressbar/bootstrap-progressbar.min.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/iCheck/icheck.min.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/skycons/skycons.js' }).appendTo('body');

    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/Flot/jquery.flot.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/Flot/jquery.flot.pie.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/Flot/jquery.flot.time.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/Flot/jquery.flot.stack.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/Flot/jquery.flot.resize.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/flot.orderbars/js/jquery.flot.orderBars.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/flot-spline/js/jquery.flot.spline.min.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/flot.curvedlines/curvedLines.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/DateJS/build/date.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/jqvmap/dist/jquery.vmap.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/jqvmap/dist/maps/jquery.vmap.world.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/jqvmap/examples/js/jquery.vmap.sampledata.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/moment/min/moment.min.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/vendors/bootstrap-daterangepicker/daterangepicker.js' }).appendTo('body');
    //   $('<script/>', { type: 'text/javascript', src: 'assets/build/js/custom.js' }).appendTo('body');


    // })



  }

}
