import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Role,
  UserData,
  Login,
  TokenObject,
  Token,
  JumpToRole,
} from '@core/models/user';
import { environment } from '@env';
import {
  AddCurrency,
  AddCustomer,
  AddFuels,
  AddItem,
  AddItemsCategory,
  AddSubMainStock,
  AddSubItemsCategory,
  AddSupplier,
  AddSupplierItems,
  AddUomCategory,
  Currency,
  CurrencySymboles,
  EditCurrency,
  EditCustomer,
  EditFuels,
  EditItem,
  EditItemsCategory,
  EditSubMainStock,
  EditSubItemsCategory,
  EditSupplier,
  EditSupplierItems,
  EditUomCategory,
  AddMainStock,
  EditMainStock,
  AddTransactionsType,
  EditTransactionsType,
  AddTransactions,
  EditTransactions,
  EditFines,
  AddFines,
  AddDrivers,
  EditDrivers,
  EditInvoices,
  AddInvoices,
  AddWorkingOrder,
  AddMaintenanceTypes,
  EditMaintenanceTypes,
  AddMaintenanceCenters,
  EditMaintenanceCenters,
  AddComplaints,
  EditComplaints,
  AddTechnicians,
  EditTechnicians,
  GetReports,
  GetNotifications,
  EditWorkingOrder,
} from '@core/models/items';
import { Location } from '@angular/common';
import * as jwt from 'jsonwebtoken';
import { genSaltSync, hashSync, compareSync } from 'bcryptjs';
import { env } from 'process';
declare var Swal;
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private url = environment.url;
  private token: string;
  private role: Role;
  private userData: UserData;
  private RSA_PRIVATE_KEY = environment['privateKey'];
  lang: string;
  constructor(
    private http: HttpClient,
    private cookie: CookieService,
    private router: Router,
    private location: Location
  ) {
    this.lang = this.cookie.get('Lang');
  }

  login(login: Login): Observable<any> {
    return this.http.post(`${this.url}/login/authenticate`, login).pipe(
      tap((response) => {
        if (response.Status.code === 200) {
          // const user = this.hashPassword(login.Password);
          // const userToken = this.hashUserName(login.Username);
          // const token = jwt.sign({ user, userToken}, this.RSA_PRIVATE_KEY , {algorithm: 'HS256'});
          // console.log(token , jwt.decode(token))

          const data = response.Response.data;
          const role: Role = response.Response.data.role;
          const jumpToRole: JumpToRole = response.Response.data.jumpToRole;
          if (!role.fleet_pg) {
            window.location.href =
              'https://fleet.nasam.net/Default.aspx#';
          }

          const userData: UserData = {
            userId: data.userId,
            userName: data.userName,
            personName: data.personName,
            userEmail: data.userEmail,
            timeZone: data.timeZone,
            endDate: data.endDate,
          };
          this.saveData(role, jumpToRole, userData);
          this.startTokenTimer(userData.endDate);
        }
        return response;
      })
    );
  }
  FileJson(lang): Observable<any> {
    return this.http.get(`assets/i18n/${lang || 'en-us'}.json`);
  }

  private clearTimeOut;

  private startTokenTimer(endDate: number) {
    let timeOut: number = endDate;

    let loop = () => {
      if (timeOut <= 2147483647) {
        this.clearTimeOut = setTimeout(() => this.logoutSession(), timeOut);
      } else {
        timeOut = timeOut - 2147483647;
        this.clearTimeOut = setTimeout(() => loop(), 2147483647);
      }
    };
    loop();
  }

  stopTokenTimer() {
    clearTimeout(this.clearTimeOut);
  }

  // loginCode(obj): Observable<any> {
  //   return this.http.post(`${this.url}/login/checkadmincode`, obj).pipe(
  //     tap((response) => {
  //       if (response.Status.code == 200) {
  //         var data = response.Response.LoggedUser;
  //         var token = response.Status.token;
  //         var role = response.Response.LoggedUser.role;
  //         var userData = {
  //           userid: data.userid,
  //           name: data.name,
  //           image: data.image,
  //         };
  //         this.saveData(token, role, userData);
  //       }
  //       return response;
  //     })
  //   );
  // }

  logout(): void {
    this.stopTokenTimer();
    this.cookie.deleteAll('*/*');
    this.cookie.deleteAll('/');
    this.cookie.deleteAll();
    window.location.href = 'https://fleet.nasam.net/Default.aspx';
  }

  logoutSession(): void {
    this.stopTokenTimer();
    this.cookie.deleteAll('*/*');
    this.cookie.deleteAll('/');
    this.cookie.deleteAll();
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: 'your session has expired',
      showConfirmButton: true,
      timer: 5000,
    });
    setTimeout(() => {
      window.location.href =
        'https://fleet.nasam.net/Default.aspx#';
    }, 1000);
  }

  // Manage Token
  getToken(): string {
    if (this.checkToken()) {
      this.token = this.cookie.get('token');
    } else {
      this.token = '';
    }
    return this.token;
  }

  checkToken(): boolean {
    return this.cookie.check('token');
  }

  getRole(): Role {
    if (this.cookie.check('role')) {
      this.role = JSON.parse(this.cookie.get('role'));
    }
    return this.role;
  }

  getUserData(): UserData {
    if (this.checkToken()) {
      this.userData = JSON.parse(this.cookie.get('userData'));
    }
    return this.userData;
  }

  isLoggedIn(): boolean {
    return this.cookie.check('Auth');
  }

  saveData(role: Role, jumpToRole: JumpToRole, userData: UserData): void {
    this.cookie.set('Auth', 'true', null, '/');
    this.cookie.set('role', JSON.stringify(role), null, '/');
    this.cookie.set('jumpToRole', JSON.stringify(jumpToRole), null, '/');
    this.cookie.set('userData', JSON.stringify(userData), null, '/');
  }

  //   Category

  getItemsCategory(obj: {}): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_categories/getitemcategories`,
      obj
    );
  }

  deleteCategory(id): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_categories/deleteCategory?id=${id}`,
      {}
    );
  }

  editCategory(editItemsCategory: EditItemsCategory): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_categories/editCategory`,
      editItemsCategory
    );
  }

  addItemsCategory(addItemsCategory: AddItemsCategory): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_categories/postCategory`,
      addItemsCategory
    );
  }

  // Sub category
  addSubItemsCategory(
    addSubItemsCategory: AddSubItemsCategory
  ): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_sub_categories/postSubCategory`,
      addSubItemsCategory
    );
  }

  getSubItemsCategory(obj: any): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_sub_categories/getSubCategories`,
      obj
    );
  }

  editSubCategory(editSubItemsCategory: EditSubItemsCategory): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_sub_categories/editSubCategory`,
      editSubItemsCategory
    );
  }

  deleteSubCategory(id): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_sub_categories/deleteSubCategory?id=${id}`,
      {}
    );
  }

  // UOM

  getUomCategory(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_uom/getUoms`, obj);
  }

  deleteUomCategory(id): Observable<any> {
    return this.http.post(`${this.url}/fleet_uom/deleteUom?id=${id}`, {});
  }

  addUomCategory(addUomCategory: AddUomCategory): Observable<any> {
    return this.http.post(`${this.url}/fleet_uom/postUom`, addUomCategory);
  }

  editUomCategory(editUomCategory: EditUomCategory): Observable<any> {
    return this.http.put(`${this.url}/fleet_uom/editUom`, editUomCategory);
  }

  // cuurency

  currenctSymboles(): Observable<any> {
    return this.http.get('assets/data/currency.json');
  }

  getCurrency(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_currencies/getCurrencies`, obj);
  }

  deleteCurrency(id): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_currencies/deleteCurrency?id=${id}`,
      {}
    );
  }

  addCurrency(addCurencyy: AddCurrency): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_currencies/postCurrency`,
      addCurencyy
    );
  }

  editCurrency(editCurencyy: EditCurrency): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_currencies/editCurrency`,
      editCurencyy
    );
  }

  // Fuels

  getFuels(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_fuel/getFuels`, obj);
  }

  deleteFuels(id): Observable<any> {
    return this.http.post(`${this.url}/fleet_fuel/deleteFuel?id=${id}`, {});
  }

  addFuels(addFuels: AddFuels): Observable<any> {
    return this.http.post(`${this.url}/fleet_fuel/postFuel`, addFuels);
  }

  editFuels(editFuels: EditFuels): Observable<any> {
    return this.http.put(`${this.url}/fleet_fuel/editFuel`, editFuels);
  }

  // suppliers

  getSuppliers(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_supplier/getSuppliers`, obj);
  }

  deleteSuppliers(id): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_supplier/deleteSupplier?id=${id}`,
      {}
    );
  }

  addSuppliers(addSuppliers: AddSupplier): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_supplier/postSupplier`,
      addSuppliers
    );
  }

  editSuppliers(editSuppliers: EditSupplier): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_supplier/editSupplier`,
      editSuppliers
    );
  }

  // Itemssuppliers

  getSuppliersItems(
    supplierId: number,
    categoryId: number,
    subCategoryId: number
  ): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_supplier/getSupplierItems?supplierId=${
        supplierId == null ? 0 : supplierId
      }&categoryId=${categoryId == null ? 0 : categoryId}&subCategoryId=${
        subCategoryId == null ? 0 : subCategoryId
      }`,
      {}
    );
  }

  deleteSuppliersItems(id): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_supplier/deleteItemSupplier?id=${id}`,
      {}
    );
  }

  addSuppliersItems(addSuppliersItems: AddSupplierItems): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_supplier/postItemSupplier`,
      addSuppliersItems
    );
  }

  editSuppliersItems(editSuppliersItems: EditSupplierItems): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_supplier/editItemSupplier`,
      editSuppliersItems
    );
  }

  // Customer

  getCustomers(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_customers/getCustomers`, obj);
  }

  deleteCustomers(id): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_customers/deleteCustomer?id=${id}`,
      {}
    );
  }

  addCustomers(addCustomers: AddCustomer): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_customers/postCustomer`,
      addCustomers
    );
  }

  editCustomers(editCustomers: EditCustomer): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_customers/editCustomer`,
      editCustomers
    );
  }

  // items

  getItemsPrerequistes(obj): Observable<any> {
    return this.http.post(`${this.url}/fleet_items/getItemPrerequistes`, obj);
  }

  getItems(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_items/getItems`, obj);
  }

  deleteItem(id): Observable<any> {
    return this.http.post(`${this.url}/fleet_items/deleteItem?id=${id}`, {});
  }

  addItem(addItem: AddItem): Observable<any> {
    return this.http.post(`${this.url}/fleet_items/postItem`, addItem);
  }

  editItem(editItem: EditItem): Observable<any> {
    return this.http.put(`${this.url}/fleet_items/editItem`, editItem);
  }

  // SubmainStcok

  addSubMainStock(addSubMainStock: AddSubMainStock): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_substock/postSubstock`,
      addSubMainStock
    );
  }
  editSubMainStock(EditSubMainStock: EditSubMainStock): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_substock/editSubstock`,
      EditSubMainStock
    );
  }
  getSubMainStock(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_substock/getSubstocks`, obj);
  }
  getSubMainStockItems(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_substock/getSubstockItems?substockId=${id}`,
      {}
    );
  }
  deleteSubMainStock(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_substock/deleteSubstock?id=${id}`,
      {}
    );
  }

  // MainStock
  addMainStock(addMainStock: AddMainStock): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_main_stock/postMainstock`,
      addMainStock
    );
  }

  editMainStock(EditMainStock: EditMainStock): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_main_stock/editMainstock`,
      EditMainStock
    );
  }

  getMainStock(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_main_stock/getMainstocks`, obj);
  }

  getMainStockItems(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_main_stock/getMainstockItems?mainstockId=${id}`,
      {}
    );
  }

  deleteMainStock(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_main_stock/deleteMainstock?id=${id}`,
      {}
    );
  }

  // transActions Types
  addTransactionsType(
    addTransactionsType: AddTransactionsType
  ): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_transactiontype/postTransactionType`,
      addTransactionsType
    );
  }
  editTransactionsType(
    EditTransactionsType: EditTransactionsType
  ): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_transactiontype/editTransactionType`,
      EditTransactionsType
    );
  }

  getTransactionsType(obj: any): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_transactiontype/getTransactionType`,
      obj
    );
  }

  getTransactionsTypePre(): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_transactiontype/getTransactionTypePreques`,
      {}
    );
  }

  deleteTransactionsType(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_transactiontype/deleteTransactionType?id=${id}`,
      {}
    );
  }

  // transActions
  addTransactions(addTransactions: AddTransactions): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_transaction/postTransaction`,
      addTransactions
    );
  }

  editTransactions(EditTransactions: EditTransactions): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_transaction/editTransaction`,
      EditTransactions
    );
  }

  getTransactions(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_transaction/getTransactions`, obj);
  }
  getTransactionsPre(): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_transaction/getTransactionPreques`,
      {}
    );
  }

  deleteTransactions(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_transaction/deleteTransaction?id=${id}`,
      {}
    );
  }

  getTransactionsItems(object: {
    transactionTypeId: number;
    fromId: number;
  }): Observable<any> {
    return this.http.post(`${this.url}/fleet_transaction/getItems`, object);
  }

  getTransactionsHistory(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_transaction/getTransactionHistory?transactionId=${id}`,
      {}
    );
  }

  // Fines
  addFines(addFines: AddFines): Observable<any> {
    return this.http.post(`${this.url}/fleet_fines/postFine`, addFines);
  }

  editFines(EditFines: EditFines): Observable<any> {
    return this.http.put(`${this.url}/fleet_fines/editFine`, EditFines);
  }

  deleteFines(id: number): Observable<any> {
    return this.http.post(`${this.url}/fleet_fines/deleteFine?id=${id}`, {});
  }

  getFines(
    vehicleId: number,
    currencyId: number,
    driverId: number,
    obj: any
  ): Observable<any> {
    var url = this.url + '/fleet_fines/getFines';
    if (vehicleId || currencyId || driverId) {
      url += '?';
      vehicleId !== null ? (url += `vehicleId=${vehicleId}`) : null;

      vehicleId !== null && (currencyId !== null || driverId !== null)
        ? (url += '&')
        : null;

      currencyId !== null ? (url += `currencyId=${currencyId}`) : null;

      currencyId !== null && driverId !== null ? (url += '&') : null;

      driverId !== null ? (url += `driverId=${driverId}`) : null;
    }
    return this.http.post(url, obj);
  }

  getFinesPre(): Observable<any> {
    return this.http.post(`${this.url}/fleet_fines/getFinePrerequistes`, {});
  }

  // Drivers
  addDrivers(addDrivers: AddDrivers): Observable<any> {
    return this.http.post(`${this.url}/fleet_driver/postDriver`, addDrivers);
  }

  editDrivers(EditDrivers: EditDrivers): Observable<any> {
    return this.http.put(`${this.url}/fleet_driver/editDriver`, EditDrivers);
  }

  deleteDrivers(id: number): Observable<any> {
    return this.http.post(`${this.url}/fleet_driver/deleteDriver?id=${id}`, {});
  }

  getDrivers(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_driver/getDrivers`, obj);
  }

  // invoices
  editInvoices(editInvoces: EditInvoices): Observable<any> {
    return this.http.put(`${this.url}/fleet_invoice/editInvoice`, editInvoces);
  }

  addInvoices(addInvoces: AddInvoices): Observable<any> {
    return this.http.post(`${this.url}/fleet_invoice/postInvoice`, addInvoces);
  }

  getInvoices(val: string, obj: any): Observable<any> {
    var url = this.url + '/fleet_invoice/getInvoices',
      obj;
    if (val === 'false' || val === 'true') {
      url += '?isInInvoice=' + JSON.parse(val);
    }
    return this.http.post(url, obj);
  }

  deleteInvoices(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_invoice/deleteInvoice?id=${id}`,
      {}
    );
  }

  getInvoiceBodyPreques(): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_invoice/getInvoiceBodyPreques`,
      {}
    );
  }

  getInvoiceFooterPreques(object: any): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_invoice/getInvoiceFooterPreques`,
      object
    );
  }

  getInvoiceHistory(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_invoice/getInvoiceHistory?invoiceId=${id}`,
      {}
    );
  }

  // WorkingOrders

  getWorkingOrders(obj: any): Observable<any> {
    // var url = this.url + '/fleet_workingorder/getWorkingOrders';
    // if (vehicleId || maintenanceTypeId || mentainenceCenterId) {
    //   url += '?';
    //   vehicleId !== null ? (url += `vehicleId=${vehicleId}`) : null;
    //   maintenanceTypeId !== null
    //     ? (url += `&maintenanceTypeId=${MaintenanceTypeId}`)
    //     : null;
    //   mentainenceCenterId !== null
    //     ? (url += `&maintenanceCenterId=${mentainenceCenterId}`)
    //     : null;
    // }
    return this.http.post(
      this.url + '/fleet_workingorder/getWorkingOrders',
      obj
    );
  }

  getWorkingOrdersPre(): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_workingorder/getWorkingOrderPreques`,
      {}
    );
  }

  getWorkingOrdersHistory(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_workingorder/getWorkingOrderHistory?workingOrderId=${id}`,
      {}
    );
  }

  deleteWorkingOrders(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_workingorder/deleteWorkingOrder?id=${id}`,
      {}
    );
  }

  addWorkingOrder(addWorkingOrder: AddWorkingOrder): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_workingorder/postWorkingOrder`,
      addWorkingOrder
    );
  }

  editWorkingOrder(editWorkingOrder: EditWorkingOrder): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_workingorder/editWorkingOrder`,
      editWorkingOrder
    );
  }

  // maintenance Type
  getMaintenanceType(obj: any): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_maintenance_types/getMaintenanceTypes`,
      obj
    );
  }

  deleteMaintenanceType(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_maintenance_types/deleteMaintenanceType?id=${id}`,
      {}
    );
  }

  addMaintenanceType(
    addMaintenanceTypes: AddMaintenanceTypes
  ): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_maintenance_types/postMaintenanceType`,
      addMaintenanceTypes
    );
  }
  editMaintenanceType(
    editMaintenanceTypes: EditMaintenanceTypes
  ): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_maintenance_types/editMaintenanceType`,
      editMaintenanceTypes
    );
  }

  // maintenance Center
  getMaintenanceCenter(obj: any): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_maintenance_Center/getMaintenanceCenters`,
      obj
    );
  }

  deleteMaintenanceCenter(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_maintenance_Center/deleteMaintenanceCenter?id=${id}`,
      {}
    );
  }

  addMaintenanceCenter(
    addMaintenanceCenters: AddMaintenanceCenters
  ): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_maintenance_Center/postMaintenanceCenter`,
      addMaintenanceCenters
    );
  }
  editMaintenanceCenter(
    editMaintenanceCenters: EditMaintenanceCenters
  ): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_maintenance_Center/editMaintenanceCenter`,
      editMaintenanceCenters
    );
  }

  // Complaint
  getComplaintPre(): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_complaint/getComplaintPreques`,
      {}
    );
  }

  getComplaint(
    vehicleId: number,
    mentainenceCenterId: number,
    obj: any
  ): Observable<any> {
    var url = this.url + '/fleet_complaint/getComplaints';
    if (vehicleId || mentainenceCenterId) {
      url += '?';
      vehicleId !== null ? (url += `vehicleId=${vehicleId}`) : null;
      vehicleId !== null && mentainenceCenterId !== null ? (url += '&') : null;
      mentainenceCenterId !== null
        ? (url += `maintenanceCenterId=${mentainenceCenterId}`)
        : null;
    }

    return this.http.post(url, obj);
  }
  deleteComplaint(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_complaint/deleteComplaint?id=${id}`,
      {}
    );
  }

  addComplaint(addComplaints: AddComplaints): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_complaint/postComplaint`,
      addComplaints
    );
  }
  editComplaint(editComplaints: EditComplaints): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_complaint/editComplaint`,
      editComplaints
    );
  }

  // technician
  getTechnician(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_technician/getTechnicians`, obj);
  }

  deleteTechnician(id: number): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_technician/deleteTechnician?id=${id}`,
      {}
    );
  }

  addTechnician(addTechnicians: AddTechnicians): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_technician/postTechnician`,
      addTechnicians
    );
  }
  editTechnician(editTechnicians: EditTechnicians): Observable<any> {
    return this.http.put(
      `${this.url}/fleet_technician/editTechnician`,
      editTechnicians
    );
  }

  // reports
  getReportsFiltersPre(text: string): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_reports/getAllReportFilters?reportApendex=${text}`,
      {}
    );
  }

  getComplaintsReport(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_reports/getComplaints`, obj);
  }

  getTransactionsReport(obj: any): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_reports/getTransactionsReport`,
      obj
    );
  }

  getWorkingOrdersReport(obj: any): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_reports/getWorkingOrdersReport`,
      obj
    );
  }

  getInvoicesReport(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_reports/getInvoicesReport`, obj);
  }

  getFinesReport(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_reports/getFinesReport`, obj);
  }

  getFinancialReport(obj: any): Observable<any> {
    return this.http.post(`${this.url}/fleet_reports/getFinancialReport`, obj);
  }

  getDashBoard(dateFrom, dateTo): Observable<any> {
    return this.http.post(`${this.url}/fleet_dashboard/getDashboard`, {
      dateFrom,
      dateTo,
    });
  }

  // notification

  getNotificationsPre(): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_notification/getNotificationsPrerequistes`,
      {}
    );
  }

  getNotificationsAfterDate(notifications: GetNotifications): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_notification/getNotificationsAfterDate`,
      notifications
    );
  }

  getNotificationsBeforeDate(notifications: GetNotifications): Observable<any> {
    return this.http.post(
      `${this.url}/fleet_notification/getNotificationsBeforeDate`,
      notifications
    );
  }

  getWeather7(): Observable<any> {
    return this.http.get(
      `https://api.openweathermap.org/data/2.5/forecast/daily?units=Metric&q=cairo&appid=eb03b1f5e5afb5f4a4edb40c1ef2f534&cnt=7`
    );
  }

  private hashPassword(password: string): string {
    const salt = genSaltSync(12);
    const hashedPassword = hashSync(password, salt);
    return hashedPassword;
  }

  private hashUserName(userNAme: string): string {
    const salt = genSaltSync(12);
    const hashedUserNAme = hashSync(userNAme, salt);
    return hashedUserNAme;
  }
  private verifyPassword(passwordAttempted, hashedPassword): boolean {
    return compareSync(passwordAttempted, hashedPassword);
  }
}
